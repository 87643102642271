import React from 'react'
import styled from 'styled-components'
import moment from 'moment'
import { RichText } from 'prismic-reactjs'

import Image from './Image'
import utils from '../utils'

const Wrapper = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-bottom: 1.25rem;
`

const ImageWrapper = styled.div`
	width: 5.687rem;
	height: 5.687rem;
	line-height: 5.687rem;
	padding: 0;
	border-radius: 50%;
	overflow: hidden;
	display: inline-block;
	background-color: ${(props) => props.theme.colors.light};
`

const TitleWrapper = styled.div`
	display: inline-block;
	width: calc(100% - 5.687rem);
	padding-left: 2.187rem;
	vertical-align: middle;
	> p {
		padding-bottom: 0;
	}
`

const Title = styled.div`
	font-family: FuturaBTBold, Futura, RobotoBold, Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
	font-size: 1.315rem;
`

const Avatar = ({ author, date, image, text }) => {
	return (
		<Wrapper>
			{image && (
				<ImageWrapper>
					<Image {...image} />
				</ImageWrapper>
			)}
			{author && (
				<TitleWrapper>
					<Title>{`${author}, ${moment(date).format('Do MMMM YYYY')}`}</Title>
					<RichText render={text} htmlSerializer={utils.htmlSerializer} />
				</TitleWrapper>
			)}
		</Wrapper>
	)
}

export default Avatar
