import React from 'react'
import { graphql, Link } from 'gatsby'
import { RichText } from 'prismic-reactjs'

import styled from 'styled-components'
import _get from 'lodash/get'
import _map from 'lodash/map'

import Layout from '../components/layout'
import SEO from '../components/seo'
import H1 from '../components/H1'
import H2 from '../components/H2'
import H4 from '../components/H4'

import Avatar from '../components/Avatar'
import Image from '../components/Image'

import media from '../styles/media'
import utils from '../utils'
import theme from '../styles/theme'

export const query = graphql`
	query ArticleQuery($uid: String) {
		prismic {
			allBlog_posts(uid: $uid) {
				edges {
					node {
						...Blog_article
					}
				}
			}
		}
	}
`

const Container = styled.div`
	display: flex;
	flex-direction: column;
	max-width: 1440px;
	margin: 0 auto;
	padding: 0 1rem;

	${media.greaterThan('tablet')`
    padding: 0 2rem;  
  `};

	${media.greaterThan('desktop')`
    padding: 0;  
  `};
`

const Wrapper = styled.div`
	display: flex;
	flex-direction: row;
	margin-top: 1.562rem;
	${media.lessThan('desktop')`
    flex-direction: column;
  `};
`

const ContentWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	flex: 1;

	${media.greaterThan('desktop')`
    flex: 0.6;
    padding-right: 3.125rem;
  `};
`

const BannerWrapper = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	max-width: initial;
	margin-bottom: 2rem;
	padding: 2rem 2.9rem;
	color: ${(props) => props.theme.colors.white};
	background: ${(props) => props.theme.colors.primary};

	${media.greaterThan('tablet')`
    padding: 4rem 4.18rem 4rem 6.5rem;
    margin-bottom: 3.75rem;
  `};

	${media.greaterThan('desktop')`
    max-width: 809px;
  `};
`

const TitleWrapper = styled.div`
	display: flex;
	margin-left: 0;

	${media.lessThan('desktop')`
    padding-left: 0.85rem;
    padding-right: 0.85rem;
  `};
	${media.greaterThan('desktop')`
    margin-left: 108px;
  `};
`

const MainContent = styled.div`
	display: flex;
	flex-direction: column;
	margin: 0;

	${media.greaterThan('desktop')`
    margin-left: 108px;
    margin-bottom: 4rem;
  `};
`

const SideContent = styled.div`
	display: flex;
	flex: 0.4;
	flex-direction: column;
	> img {
		width: 100%;
	}

	${media.lessThan('desktop')`
    flex: 1;
  `};
`

const AvatarWrapper = styled.div`
	padding-top: 2.5rem;
	padding-bottom: 1.25rem;
`

const RelatedResourceWrapper = styled.div`
	margin-top: 1.75rem;
`

const RelatedResourceLink = styled(Link)`
  display: block;
  font-family: FuturaBTBold, Futura, RobotoBold, Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 1.065rem;
  line-height: 1.17;
	padding: .75rem 0;
	color: ${theme.colors.darkSecondary}
`

const Blog = ({ data, location }) => {
	const doc = data.prismic.allBlog_posts.edges.slice(0, 1).pop()

	if (!doc) return null

	const {
		meta_title,
		meta_description,
		meta_keywords,
		meta_image,
		no_index,
		heading,
		author,
		author_avatar,
		author_text,
		hero_image,
		related_resource,
		publish_date,
		banner_text,
		content_area
	} =
		_get(doc, 'node') || {}
	return (
		<Layout transparentHeader>
			<SEO
				title={meta_title}
				description={meta_description}
				keywords={meta_keywords}
				image={meta_image}
				url={_get(location, 'href')}
				noIndex={no_index} />
			<Container>
				<TitleWrapper>
					<H1 variant="primary" color="dark">
						{heading}
					</H1>
				</TitleWrapper>
				<Wrapper>
					<ContentWrapper>
						<BannerWrapper>
							<H2 variant="smallHeading" color="light">
								{banner_text}
							</H2>
						</BannerWrapper>
						<MainContent>
							{_map(content_area, ({ content }) => (
								<RichText render={content} htmlSerializer={utils.htmlSerializer} />
							))}
						</MainContent>
					</ContentWrapper>
					<SideContent>
						<AvatarWrapper>
							<Avatar author={author} date={publish_date} image={author_avatar} text={author_text} />
						</AvatarWrapper>
						<Image {...hero_image} />
						{related_resource && (
							<RelatedResourceWrapper>
								<H4 variant="secondary">Related resource</H4>
								<RelatedResourceLink
									key={related_resource.heading}
									to={utils.linkResolver(related_resource)}
								>
									{related_resource.heading}
								</RelatedResourceLink>
								<RichText
									render={related_resource.listing_description}
									htmlSerializer={utils.htmlSerializer}
								/>
							</RelatedResourceWrapper>
						)}
					</SideContent>
				</Wrapper>
			</Container>
		</Layout>
	)
}

export default Blog
